
import styles from "./Alert.module.scss";

export const Alert = ({ children, isShow = true, type = "warning" }) => {

  if (!isShow) {
    return null
  }

  const cs = styles.container + " " + styles[type]

  return (
    <div className={cs}>
      {children}
    </div>
  );
};
