import React from "react";

import styles from "./Input.module.scss";

export const Input = ({
  value = "",
  onChange = () => { },
  onBlur = () => { },
  placeholder = "",
  disabled = false,
  name = "",
  type = "",
  warning = false,
  warningText = "",
}) => {
  return (
    <div className={styles.container}>
      <input
        type={type}
        name={name}
        className={`${styles.input} ${warning ? styles.warning : ""}`}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
        onChange={onChange}
      />
      {warning ? (
        <div className={styles.warningText}> {warningText}</div>
      ) : null}
    </div>
  );
};
