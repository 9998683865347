import { useEffect } from "react";

export function useKeyPressEvent(callback, ...codes) {

  useEffect(() => {

    const listener = event => {
      const { code } = event
      const isKeyPress = codes.includes(code)

      if (isKeyPress && callback) {
        event.preventDefault();
        callback()
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [callback]);

}
