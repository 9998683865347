import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { serverSSO } from '../utils/api'
import { Authorization } from '../utils/methods/auth'
import { parseHashObject, useGoToSsoLogin } from '../utils/methods/router'

export function VKAuth(props) {
    const router = useHistory()


    const goToSsoLogin = useGoToSsoLogin()

    useEffect(() => {
        const { search } = props.location
        const code = search.replace("?", "").split("&")[0].split("=")[1]

        if (code)
            VKAuth(code)

    }, [router])

    const VKAuth = (code) => {
        if (code) {
            const data = { vkToken: code, redirectUri: serverSSO + "auth/vk" }
            Authorization.loginVK(data, success, error)
        }

        function success(res) {
            console.log("toke", res);
            localStorage.setItem("token_service", "vk")
            localStorage.setItem("token", res.token)
            goToSsoLogin()
        }

        function error(err) {
            console.log(err);
        }
    }

    return <>
        <div>Вы успешно авторизовались через VK</div>
    </>
}
