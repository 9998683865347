import { useEffect, useState } from "react";

import { Button } from "../components/UI-kit/Button/Button";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { Input } from "../components/UI-kit/Input/Input";
import { Row } from "../components/UI-kit/Row/Row";
import { Title } from "../components/UI-kit/Title/Title";

import { Authorization } from "../utils/methods/auth";
import { ButtonSSO } from "../components/UI-kit/Button/ButtonSSO/ButtonSSO";
import { Tabs } from "../components/UI-kit/Tabs/Tabs";
import { Alert } from "../components/UI-kit/Alert/Alert";

import GoogleLogin from "react-google-login";

import { serverSSO } from "../utils/api";
import { useKeyPressEvent } from "../utils/hooks/useKeyPressEvent";

import { Link } from "react-router-dom";
import { getQueryParameter, useGoToSsoLogin } from "../utils/methods/router";

export function Auth(props) {
  let confirm = getQueryParameter(props, "confirm");

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [authData, setAuthData] = useState(null);

  const goToSsoLogin = useGoToSsoLogin();

  useEffect(() => {
    let request = getQueryParameter(props, "request");

    if (request) {
      const encodedRequest = encodeURIComponent(request);
      localStorage.setItem("request", encodedRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAuthData();
  }, []);

  const loadAuthData = () => {
    Authorization.loadSSOAuthData(success);

    function success(res) {
      setAuthData(res);
    }
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const authorize = () => {
    setIsLoading(true);
    Authorization.login(data, success, setAuthErrorMessage);

    function success(res) {
      setIsLoading(false);
      localStorage.setItem("token_service", "default");
      localStorage.setItem("token", res?.token);
      goToSsoLogin();
    }
  };

  const setAuthErrorMessage = (err) => {
    setIsLoading(false);
    err.then((errDescription) => {
      const message = errDescription.message;
      const email = errDescription?.validationErrors?.email;

      if (email) {
        setError(email);
        return;
      }

      setError(message);
    });
  };

  const handleGoogleResponse = (res) => {
    const token = res?.tokenObj?.id_token;
    GoogleAuth(token);
  };

  const GoogleAuth = (token) => {
    if (token) {
      const data = { googleToken: token };
      Authorization.loginGoogle(data, success, error);
    }

    function success(res) {
      localStorage.setItem("token_service", "google");
      localStorage.setItem("token", res.token);
      goToSsoLogin();
    }

    function error(err) {
      console.log(err);
    }
  };

  const handleGoogleResponseErrpr = (err) => {
    console.log(err);
  };

  const isEmptyFields = data.email === "" || data.password === "";

  const isDisabledAuth = isEmptyFields || isLoading;

  useKeyPressEvent(isDisabledAuth ? null : authorize, "Enter", "NumpadEnter");

  return (
    <>
      <WindowCenteredContainer>
        <Title>Infometeos</Title>
        <Tabs
          options={[
            { label: "Вход", href: "/auth" },
            { label: "Регистрация", href: "/register" },
          ]}
        />
        <Input onChange={handleInputChange} name="email" value={data.email} placeholder={"Email"} />

        <Input
          onChange={handleInputChange}
          type="password"
          name="password"
          value={data.password}
          placeholder={"Пароль"}
        />
        {error ? <Alert type="warning">{error}</Alert> : null}

        {confirm ? (
          confirm === 2 ? (
            <Alert type="success">Email успешно подтвержден</Alert>
          ) : (
            <Alert type="warning">Не удалось подтвердить email</Alert>
          )
        ) : null}

        <Button style={{ marginTop: 20 }} onClick={authorize} type={"primary"}>
          Войти
        </Button>
        <Button style={{ margin: 0 }} type={"href"}>
          <Link to="/forgot-password">Забыли пароль?</Link>
        </Button>

        <div style={{ margin: "20px 0 10px 0", color: "#cad3e0" }}>Войти с помощью</div>
        <Row>
          <a
            href={`https://oauth.vk.com/authorize?client_id=${
              authData?.vk.clientId
            }&display=page&scope=email&response_type=code&v=5.131&state=123&redirect_uri=${encodeURIComponent(
              serverSSO + "auth/vk"
            )}`}
          >
            <ButtonSSO type="vk">VK</ButtonSSO>
          </a>

          {authData?.google.clientId ? (
            <GoogleLogin
              clientId={authData?.google.clientId}
              render={(renderProps) => (
                <ButtonSSO onClick={renderProps.onClick} type="google">
                  Google
                </ButtonSSO>
              )}
              onSuccess={handleGoogleResponse}
              onFailure={handleGoogleResponseErrpr}
              cookiePolicy={"single_host_origin"}
            />
          ) : (
            <ButtonSSO type="google">Google</ButtonSSO>
          )}
          <a
            href={`https://oauth.yandex.ru/authorize?response_type=token&client_id=${
              authData?.yandex.clientId
            }&state=12345&redirect_uri=${encodeURIComponent(serverSSO + "auth/yandex")}`}
          >
            <ButtonSSO type="yandex">Яндекс</ButtonSSO>
          </a>
        </Row>
      </WindowCenteredContainer>
    </>
  );
}
