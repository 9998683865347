import ReactDOM from 'react-dom'
import { AlertMessage } from '../components/UI-kit/AlertCommon/AlertMessage/AlertMessage';


export let apiserver = process.env.BASE_URL;

export const serverSSO = process.env.REACT_APP_SSO_URL

export async function postData(url = "", data = {}, api, options = { contentType: "application/json" }) {
  let urlToRequest = apiserver

  if (api != "") {
    urlToRequest = api
  }
  const token = localStorage.getItem("token")

  let headers = {
    "Authorization": "Bearer " + token,
    "Content-Type": options.contentType,
    'Access-Control-Allow-Origin': '*'
  }

  if (options.contentType == "application/json") {
    data = JSON.stringify(data)
  }
  else {
    headers = {}
    data = getFormData(data)
  }

  const response = await fetch(urlToRequest + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers,
    mode: 'cors',
    body: data,
  });

  const { status } = response

  const isShowErrorMessage = status == 400 || status >= 404
  const isEmptyContent = status == 204
  const isNotSuccess = status != 200

  if (isShowErrorMessage) {
    showCommonErrorMessage(response)
  }

  if (isEmptyContent) {
    return null
  }

  if (isNotSuccess) {
    throw response.json();
  }

  return response.json();
}


export async function getData(url = "", data = {}, api = "") {
  let urlToRequest = apiserver

  if (api != "") {
    urlToRequest = api
  }

  const token = localStorage.getItem("token")

  const response = await fetch(urlToRequest + url + constructURLData(data), {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      "Authorization": "Bearer " + token
    },
  });

  const { status } = response

  const isShowErrorMessage = status == 400 || status >= 404
  const isEmptyContent = status == 204
  const isNotSuccess = status != 200

  if (isShowErrorMessage) {
    showCommonErrorMessage(response)
  }

  if (isEmptyContent) {
    return null
  }

  if (isNotSuccess) {
    throw response.json();
  }

  return response.json();
}

const showCommonErrorMessage = (err) => {
  const { status, statusText } = err

  err.clone().json().then(({ message }) => {
    ReactDOM.render(
      <AlertMessage
        status={status}
        statusText={statusText}
        message={message}
        time={new Date()}
      />,
      document.getElementById("alert")
    );
  })

}

export const constructURLData = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};


const getFormData = (obj) => {
  var formData = new FormData();

  for (var key in obj) {
    formData.append(key, obj[key]);
  }

  return formData
}


