import { AlertCommon } from "../../AlertCommon/AlertCommon";
import styles from "./WindowCenteredContainer.module.scss";

export const WindowCenteredContainer = ({ children, maxWidth = "default", style = {} }) => {
  const maxWidthPx = {
    "default": 400,
    lg: 1000
  }
  return <div className={styles.container} style={{ maxWidth: maxWidthPx[maxWidth], ...style }}>
    {children}
    <AlertCommon />
  </div>;
};
