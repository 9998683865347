import { useEffect, useMemo, useState } from "react";


import { Button } from "../components/UI-kit/Button/Button";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { Input } from "../components/UI-kit/Input/Input";
import { Row } from "../components/UI-kit/Row/Row";
import { Title } from "../components/UI-kit/Title/Title";

import { Authorization } from "../utils/methods/auth";
import { ButtonSSO } from "../components/UI-kit/Button/ButtonSSO/ButtonSSO";
import { Tabs } from "../components/UI-kit/Tabs/Tabs";
import { Checkbox } from "../components/UI-kit/Checkbox/Checkbox";
import { Alert } from "../components/UI-kit/Alert/Alert";

import { useKeyPressEvent } from "../utils/hooks/useKeyPressEvent";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export function Register() {
  const [data, setData] = useState({
    email: "",
    name: "",
    password: "",
    repeatPassword: "",
  });

  const [isAgreePolicy, setIsAgreePolicy] = useState(false)

  const toggleIsAgreePolicy = () => setIsAgreePolicy(!isAgreePolicy)

  const [isCorrectPassword, setIsCorrectPassword] = useState(true)

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null)

  const router = useHistory()

  useEffect(() => {
    checkPasswordCorrect()
  }, [data.password, data.repeatPassword])

  const checkPasswordCorrect = () => {
    const { password, repeatPassword } = data
    const isEmtpy = password == "" && repeatPassword == ""
    const isCorrect = password == repeatPassword
    setIsCorrectPassword(isCorrect)
  }


  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const register = () => {
    const { email, password, name } = data

    setIsLoading(true)

    Authorization.register({ email, password, fullName: { first: name, last: "", middle: "" } }, success, fail)

    function success() {
      setIsLoading(false)
      router.push("/register-success")
    }

    function fail(err) {
      setIsLoading(false)
      err.then((errDescription) => {
        const message = errDescription.message
        const email = errDescription?.validationErrors?.email

        if (email) {
          setError(email)
          return
        }

        setError(message)
      })
    }
  }


  const isEmptyFields = data.email == "" || data.name == "" || data.password == "" || data.repeatPassword == ""

  const isDisabledRegister = isEmptyFields || !isAgreePolicy || !isCorrectPassword || isLoading

  useKeyPressEvent(isDisabledRegister ? null : register, "Enter", "NumpadEnter")

  return (
    <>

      <WindowCenteredContainer>
        <Title>Infometeos</Title>
        <Tabs options={[
          { label: "Вход", href: "/auth" },
          { label: "Регистрация", href: "/register" }
        ]} />
        <Input
          onChange={handleInputChange}
          name="email"
          value={data.email}
          placeholder={"Email"}
        />
        <Input
          onChange={handleInputChange}
          name="name"
          value={data.name}
          placeholder={"Имя"}
        />
        <Input
          onChange={handleInputChange}
          type="password"
          name="password"
          onBlur={checkPasswordCorrect}
          warning={!isCorrectPassword}
          value={data.password}
          placeholder={"Пароль"}
        />
        <Input
          onChange={handleInputChange}
          type="password"
          name="repeatPassword"
          onBlur={checkPasswordCorrect}
          warning={!isCorrectPassword}
          value={data.repeatPassword}
          placeholder={"Повторите пароль"}
        />

        {error ?
          <Alert type="warning">
            {error}
          </Alert>
          : null}

        <Alert isShow={!isCorrectPassword} type="warning">
          Пароли не совпадают. Введите одинаковые пароли.
        </Alert>

        <Alert isShow={error == null && isCorrectPassword && data.password && data.repeatPassword} type="success">
          Пароли совпадают
        </Alert>

        <Checkbox value={isAgreePolicy} onChange={toggleIsAgreePolicy}>
          <span>Я соглашаюсь с <Link to="/privacy-policy">политикой конфиденциальности</Link></span>
        </Checkbox>
        <Button onClick={register} disabled={isDisabledRegister} style={{ marginTop: 20 }} type={"primary"}>
          Регистрация
        </Button>
      </WindowCenteredContainer>
    </>

  );
}
