import { useState } from "react";

import { Button } from "../components/UI-kit/Button/Button";
import { WindowCenteredContainer } from "../components/UI-kit/containers/WindowCenteredContainer/WindowCenteredContainer";
import { Input } from "../components/UI-kit/Input/Input";
import { Title } from "../components/UI-kit/Title/Title";

import { Authorization } from "../utils/methods/auth";
import { Tabs } from "../components/UI-kit/Tabs/Tabs";
import { Alert } from "../components/UI-kit/Alert/Alert";
import { useKeyPressEvent } from "../utils/hooks/useKeyPressEvent";




export function ForgotPassword() {

  const [data, setData] = useState({
    email: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessSend, setIsSuccessSend] = useState(false);

  const [error, setError] = useState(null)


  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setData({ ...data, [name]: value });
  };


  const resetPassword = () => {
    setIsLoading(true)
    Authorization.forgotPassword(data, success, fail)

    function success() {
      setIsLoading(false)
      setIsSuccessSend(true)
      setError(null)
    }
  };

  const fail = (err) => {
    setIsLoading(false)
    console.log(err.message);
    const message = err.message
    const email = err?.validationErrors?.email

    if (email) {
      setError(email)
      return
    }

    setError(message)
  }

  const isEmptyFields = data.email == ""

  const isSubmitDisabled = isEmptyFields || isLoading
  useKeyPressEvent(isSubmitDisabled ? null : resetPassword, "Enter", "NumpadEnter")

  return (
    <>
      <WindowCenteredContainer>
        <Title>Infometeos</Title>


        <Tabs options={[
          { label: "Вход", href: "/auth" },
          { label: "Регистрация", href: "/register" }
        ]} />


        <Input
          onChange={handleInputChange}
          name="email"
          value={data.email}
          placeholder={"Email"}
        />


        {error ?
          <Alert type="warning">
            {error}
          </Alert>
          : null}


        {isSuccessSend && error == null ?
          <Alert type="success">
            Письмо для сброса пароля было успешно направлено на ваш электронный адрес
          </Alert>
          : null}


        <Button disabled={isEmptyFields || isLoading} style={{ marginTop: 20 }} onClick={resetPassword} type={"primary"}>
          Сбросить пароль
        </Button>

      </WindowCenteredContainer>
    </>

  );
}
